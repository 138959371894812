<template>
  <capillary-locations-template
    :first-blood-vessel-occurrance="firstBloodVesselOccurrance"
    :blood-vessel-locations="bloodVesselLocations"
    :gender="gender"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import CapillaryLocationsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/capillary-locations/CapillaryLocationsTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { field, requiredField, requiredArrayField } = fieldBuilder;

const FIELDS = [
  field('gender'),
  requiredField('firstBloodVesselOccurrance'),
  requiredArrayField('bloodVesselLocations')
];

export default {
  name: 'CapillaryLocations',
  components: { CapillaryLocationsTemplate },
  mixins: [makeStep(FIELDS)],
  watch: {
    firstBloodVesselOccurrance() {
      this.scrollTo('#facial-capillary-locations');
    }
  }
};
</script>
